import VideoPlayerLazy from 'react-player/lazy';
import React, { useRef, useState, useEffect, useCallback, SyntheticEvent } from 'react';
import Audio from '$icons/audio.svg';
import AudioMuted from '$icons/audio_muted.svg';
import Play from '$icons/play.svg';
import Pause from '$icons/pause.svg';
import { fetchVideoMetadata } from './utils';
import {
    StyledVideo,
    StyledVideoButton,
    StyledVideoAspect,
    StyledVideoContainer,
    StyledVideoOverlay,
} from './styled';
import { useTranslation } from '~/shared/utils/translation';
import { VisuallyHidden } from '../VisuallyHidden';
import { useCookieConsent } from '~/shared/hooks/useCookieConsent';
import { ConsentPlaceholder } from '../CookieInformation/Components/ConsentPlaceholder';
import { useVideoTracking } from './hooks';
import { Image } from '../Image';

export interface PlayButtonProps {
    playing?: boolean;
}

export interface VideoProps {
    /** Video source. Supports YouTube, Vimeo and selfhosted */
    src: string;

    /**
     * Required if selfhosted. Overwrites default poster for YouTube and Vimeo.
     * Default posters from external providers are very low resultion.
     * Providing a posterSrc is always prefered.
     */
    posterSrc?: string;

    /**
     * Show or hide controls. Only the play button will be visible until playing.
     * YouTube: Not all controls can be hidden from youtube.
     * Vimeo: Hidden controls must be enabled by video owner
     */
    controls?: boolean;

    /**
     * Show or hide custom play/pause button.
     */
    showCustomButtons?: boolean;

    /** Play the video inline on mobile devices */
    playsinline?: boolean;

    /** Start the video muted. Prefered */
    muted?: boolean;

    /** Loop the video */
    loop?: boolean;

    /** Start playing imidiately */
    playing?: boolean;

    /**
     *  Trigger recalcuating dimensions and repositioning the video.
     *  Usefull in cases where the container resizes without an window resize event
     */
    repositionKey?: string;

    /**
     * Aspect ratio.
     * If not set will automatically determine (requires an additional requst)
     */
    aspectRatio?: number;

    /**
     * Set height to 100%
     */
    cover?: boolean;

    /**
     * Decide the position of the playbutton
     */
    buttonPosition?: 'center' | 'corner';

    /**
     * Disable or overwride playbutton
     */
    playButton?: null | (({ playing }: PlayButtonProps) => JSX.Element);

    /**
     * Triggered when started playing
     */
    onPlay?: () => void;

    /**
     * Triggered on pause
     */
    onPause?: () => void;

    /**
     * Overwrite standard toggle of pause/play on overlay click
     */
    onOverlayClick?: (e: React.MouseEvent<HTMLElement>) => void;

    /**
     * Video provider specific options
     * @see https://www.npmjs.com/package/react-player
     */
    embedConfig?: { [key: string]: unknown };

    posterSizes?: JSX.IntrinsicElements['img']['sizes'];
}

const defaultEmbedOptions = {
    youtube: {
        embedOptions: {
            rel: 0,
            modestbranding: 1,
        },
    },
};

export const Video = ({
    controls = true,
    playsinline = true,
    muted = true,
    loop = false,
    playing = false,
    cover = false,
    buttonPosition = 'center',
    src,
    posterSrc,
    posterSizes,
    repositionKey,
    onPlay,
    onPause,
    onOverlayClick,
    embedConfig = defaultEmbedOptions,
    playButton: VideoPlayButton,
    aspectRatio: defaultAspectRatio,
    showCustomButtons,
}: VideoProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [aspectRatio, setAspectRatio] = useState(defaultAspectRatio);
    const [isPlaying, setIsPlaying] = useState(playing);
    const [isMuted, setIsMuted] = useState(muted || playing);
    const [isLoading, setIsLoading] = useState(true);
    const [initialPlay, setInitialPlay] = useState(playing);
    const [thumbnailSrc, setThumbnailSrc] = useState(posterSrc);
    const { translate } = useTranslation();
    const { marketing, statistic } = useCookieConsent();
    const { dispatch, dispatchProgress } = useVideoTracking();
    const aspectRatioPadding = `${(aspectRatio ?? 0.5625) * 100}%`;

    const trackState = useCallback(
        (type: 'play' | 'pause') => {
            if (loop) {
                return;
            }
            dispatch({ type, payload: { eventLabel: src } });
        },
        [dispatch, loop, src]
    );

    const onPlayHandler = useCallback(() => {
        setInitialPlay(true);
        setIsPlaying(true);

        onPlay && onPlay();
    }, [onPlay]);

    const onPauseHandler = useCallback(() => {
        setIsPlaying(false);
        onPause && onPause();
    }, [onPause]);

    const onReadyHandler = useCallback(() => {
        setIsLoading(false);
    }, [setIsLoading]);

    const togglePlaying = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            initialPlay ? setIsPlaying(!isPlaying) : onPlayHandler();
        },
        [initialPlay, isPlaying, setIsPlaying, onPlayHandler]
    );

    const toggleMute = useCallback(
        (e: SyntheticEvent) => {
            e.stopPropagation();
            setIsMuted(!isMuted);
        },
        [isMuted, setIsMuted]
    );

    /**
     * Only reposition if covering. Otherwise fluid
     */
    useEffect(() => {
        if (!aspectRatio || !cover) {
            return;
        }

        /**
         * Determin if stretch horizontal or vertical
         */
        const scaleVideo = () => {
            window.requestAnimationFrame(() => {
                const aspectElement = ref?.current;
                if (aspectElement) {
                    const parentElement = aspectElement.parentElement as HTMLDivElement;
                    const { width, height } = parentElement.getBoundingClientRect();

                    const parentRatio = height / width;
                    const verticalScale = parentRatio > aspectRatio;

                    if (verticalScale) {
                        aspectElement.style.height = '100%';
                        aspectElement.style.width = '1000%';
                    } else {
                        aspectElement.style.width = '100%';
                        aspectElement.style.height = '1000%';
                    }
                }
            });
        };

        scaleVideo();
        window.addEventListener('resize', scaleVideo);
        return () => window.removeEventListener('resize', scaleVideo);
    }, [aspectRatio, repositionKey, cover, marketing, statistic]);

    useEffect(() => {
        if (!aspectRatio || !thumbnailSrc) {
            fetchVideoMetadata(src).then(
                ({ aspectRatio: newAspectRatio, thumbnailSrc: newThumbnailSrc }) => {
                    if (!aspectRatio) {
                        setAspectRatio(newAspectRatio);
                    }
                    if (!thumbnailSrc) {
                        setThumbnailSrc(newThumbnailSrc);
                    }
                }
            );
        }
    }, [aspectRatio, src, thumbnailSrc]);

    useEffect(() => {
        playing ? onPlayHandler() : onPauseHandler();
    }, [playing, onPlayHandler, onPauseHandler]);

    if (!(marketing && statistic)) {
        return <ConsentPlaceholder marketing statistic />;
    }

    return (
        <StyledVideo cover={cover}>
            <StyledVideoContainer paddingTop={cover ? 0 : aspectRatioPadding} cover={cover}>
                {thumbnailSrc && (
                    <Image
                        src={thumbnailSrc}
                        layout="fill"
                        objectFit="cover"
                        objectPosition={'center'}
                        alt=""
                        aria-hidden="true"
                        sizes={posterSizes}
                    />
                )}
                <StyledVideoAspect ref={ref}>
                    {initialPlay && (
                        <VideoPlayerLazy
                            key={src}
                            url={src}
                            playing={isPlaying}
                            controls={isPlaying && controls}
                            playsinline={playsinline}
                            muted={isMuted}
                            volume={1}
                            loop={loop}
                            onPause={() => {
                                trackState('pause');
                                onPauseHandler();
                            }}
                            onPlay={() => {
                                trackState('play');
                                onPlayHandler();
                            }}
                            onReady={onReadyHandler}
                            onProgress={(arg) => !loop && dispatchProgress(arg.played)}
                            config={embedConfig}
                        />
                    )}
                </StyledVideoAspect>

                {showCustomButtons && VideoPlayButton && <VideoPlayButton playing={isPlaying} />}

                {showCustomButtons && !VideoPlayButton && (
                    <StyledVideoOverlay
                        onClick={onOverlayClick ?? togglePlaying}
                        playing={isPlaying}
                        loading={initialPlay && isLoading}
                        buttonPosition={buttonPosition}
                    >
                        <StyledVideoButton
                            variant="primary"
                            shape={isPlaying ? 'icon' : 'default'}
                            shade="dark"
                            onClick={togglePlaying}
                        >
                            {isPlaying ? (
                                <>
                                    <Pause aria-hidden="true" />
                                    <VisuallyHidden children={translate('modules.pauseVideo')} />
                                </>
                            ) : (
                                <>
                                    <Play aria-hidden="true" />
                                    <VisuallyHidden children={translate('modules.playVideo')} />
                                </>
                            )}
                        </StyledVideoButton>
                        {isPlaying && (
                            <StyledVideoButton
                                variant="primary"
                                shape="icon"
                                shade="dark"
                                onClick={toggleMute}
                            >
                                {isMuted ? (
                                    <>
                                        <AudioMuted aria-hidden="true" />
                                        <VisuallyHidden
                                            children={translate('modules.unmuteVideo')}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <Audio aria-hidden="true" />
                                        <VisuallyHidden children={translate('modules.muteVideo')} />
                                    </>
                                )}
                            </StyledVideoButton>
                        )}
                    </StyledVideoOverlay>
                )}

                {!showCustomButtons && (
                    <>
                        {onOverlayClick && (
                            <StyledVideoOverlay
                                onClick={onOverlayClick}
                                loading={initialPlay && isLoading}
                            ></StyledVideoOverlay>
                        )}
                    </>
                )}
            </StyledVideoContainer>
        </StyledVideo>
    );
};
